html, body {
    width: 100%;
}

a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-family: Helvetica, "Apple Color Emoji", Arial, "PingFang TC", "Heiti TC", "Microsoft Jhenghei", sans-serif;
}

.card-body {
    font-size: 1rem;
}

.wsTit {
    font-size: 1rem;
}

.ezw1, .ezw1_1 {
    width: 80%;
}

.ezw3_1 {
    width: 70%;
}

@media (max-width: 768px) {
    a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre, .card-body {
        font-size: 0.9rem;
    }

    .footer img {
        width: 5rem;
    }

    .jconfirm-title {
        font-size: 1.2rem !important;
    }

    .wTit {
        font-size: 1rem;
    }

    .wsTit {
        font-size: 0.7rem;
    }

    .ezw1, .ezw1_1, .ezw3_1 {
        width: 100%;
    }
}

@media (max-width: 360px) {
    a, b, body, em, h1, h2, h3, h4, h5, h6, html, i, img, table, li, ol, p, strong, td, th, tr, ul, footer, pre {
        font-size: 0.9rem;
    }

    .wTit {
        font-size: 0.5rem;
    }

    .wsTit {
        font-size: 0.3rem;
    }

    .ezw1, .ezw1_1, .ezw3_1 {
        width: 100%;
    }
}

/* @media (max-width: 768px) { */
/* .img-fluid {
        width:100%;
        height:auto;
    } */
/* } */


/* @media (max-width: 999.98px) {
    .img-fluid {
        width:75%;
        height:auto;
    }
} */

/* @media (min-width: 1000px) { */
/* .img-fluid {
        width:80%;
        height:auto;
    } */
/* } */

.nav-pills .nav-link {
    border: thin solid gray;
    margin: .2rem 0;
    border-radius: 0;
}

    /* .nav-link {
                padding: 1rem;
            } */

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: #4f81bd;
    }

@media (max-width: 999.98px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 30px; /* 160px; */
        z-index: 0;
        width: 15%;
    }
}

@media (min-width: 1000px) and (max-width: 1399.98px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 40px; /* 190px; */
        z-index: 0;
        width: 15%;
    }
}

@media (min-width: 1400px) {
    .logo {
        position: absolute;
        top: 0px;
        left: 80px; /* 280px; */
        /* 160 */
        z-index: 0;
    }
}

/* .page_width {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    max-width: 80rem;
} */

.page_width {
    width: 100%;
    margin: 0 auto;
    /* max-width: 75%; */
    /* max-width: 100%; */
}

.headerBar {
    /* padding: 1rem; */
    background-color: rgb(255, 255, 255, 0.7);
    /* opacity: 0.5; */
}

.headerBar .barList .nav-link {
    color: #5EA9BE !important;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 1px;
    border-radius: 4px;
    border:1px solid transparent;
}

.headerBar .barList .nav-link:hover {
    color: #3f889c !important;
    background-color: #f2f2f2;
}

.headerBar .barList .nav-link.active {
    color: #3f889c !important;
    border: 1px solid #5EA9BE;
    font-weight: bold;
}

@media (max-width: 999.98px) {
    .imgLink {
        margin-top: -30px;
    }
}

@media (min-width: 1000px) {
    .imgLink {
        margin-top: -70px;
    }
}


@media (max-width: 799.98px) {
    /* 1199.98px */
    .imgLink div img {
        width: 100px;
        height: auto;
    }
}

/* @media (min-width: 576px) and (max-width: 991.98px) {
    .imgLink div img {
        width:40%;
    }
} */

/* @media (min-width: 992px) and (max-width: 1199.98px) {
    .imgLink div img {
        width:50%;
    }
} */

@media (min-width: 800px) {
    /* 1200px */
    .imgLink div img {
        width: 181px;
        height: auto;
    }
}

@media (max-width: 999.98px) {
    .note {
        width: 100px;
        z-index: 0;
    }
}

@media (min-width: 1000px) and (max-width: 1399.98px) {
    .note {
        width: 150px;
        z-index: 0;
    }
}

@media (min-width: 1400px) {
    .note {
        width: 200px;
        z-index: 0;
    }
}


@media (max-width: 1199.98px) {
    .we-hope-you {
        width: 45%;
        height: auto;
        /* margin-top: 160px; */
    }
}

@media (min-width: 1200px) {
    .we-hope-you {
        width: 45%; /*600px;*/
        height: auto;
        /* margin-top: 200px; */
    }
}

.gradient, .img54321 {
    /*width: 100%; 600px;*/
    /* height: auto; */
    /* margin-top: 150px; */
}

@media (max-width: 799.98px) {
    .register {
        margin-top: -40px;
    }
}

@media (min-width: 800px) and (max-width: 999.98px) {
    .register {
        margin-top: -60px;
    }
}

@media (min-width: 1000px) and (max-width: 1499.98px) {
    .register {
        margin-top: -120px;
    }
}

@media (min-width: 1500px) {
    .register {
        margin-top: -190px;
        /* 270 */
    }
}

.register div img {
    width: 181px;
    height: auto;
}

@media (max-width: 1199.98px) {
    .now-start {
        width: 30%;
        height: auto;
        margin-top: 260px;
        margin-bottom: 80px;
    }
}

@media (min-width: 1200px) {
    .now-start {
        width: 35%;
        height: auto;
        margin-top: 350px;
        margin-bottom: 250px;
    }
}

/* .footer p {
    color: #4F4F4F;
} */


/******************** service ********************/

@media (max-width: 767.98px) {
    .service a span {
        font-size: 1rem;
        color: #cb7166;
        font-weight: 600;
        position: absolute;
        display: block;
        text-align: center;
        top: .5rem;
        left: 15%;
    }

    .service {
        padding: 0 !important;
    }

        .service span {
            position: absolute;
            top: .5rem;
            cursor: pointer;
            font-size: .8rem;
            left: .8rem;
        }
}

@media (min-width: 768px) and (max-width: 999.98px) {
    .service a span {
        font-size: 1.5rem;
        color: #cb7166;
        font-weight: 600;
        position: absolute;
        display: block;
        text-align: center;
        top: .5rem;
        left: 15%;
    }

    .service span {
        position: absolute;
        top: .8rem;
        cursor: pointer;
        font-size: 1rem;
        left: 1.2rem;
    }
}

@media (min-width: 1000px) and (max-width: 1399.98px) {
    .service a span {
        font-size: 2rem;
        color: #cb7166;
        font-weight: 600;
        position: absolute;
        display: block;
        text-align: center;
        top: 1rem;
        left: 15%;
    }

    .service span {
        position: absolute;
        top: 1.5rem;
        cursor: pointer;
    }
}

@media (min-width: 1400px) {
    .service a span {
        font-size: 2rem;
        color: #cb7166;
        font-weight: 600;
        position: absolute;
        display: block;
        text-align: center;
        top: 1rem;
        left: 15%;
    }

    .service span {
        position: absolute;
        top: 1.5rem;
        cursor: pointer;
    }
}


/******************** about ********************/

.aboutTxt {
    /* margin-top: -570px;
    padding: 0 120px 120px 120px; */
    /*padding: 100px 100px 0 100px;*/
}

    .aboutTxt p {
        font-size: 1.2rem;
    }

@media (max-width: 768px) {
    .aboutTxt p {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {
    .aboutTxt p {
        font-size: 0.9rem;
    }
}

.featureTxt {
    /* margin-top: -50px;
    padding: 0 250px; */
    /*padding: 0 100px;*/
}

@media (max-width: 768px) {
    .featureTxt h1, .programDiv h1, .feeDiv h1, .qaTxt h1 {
        font-size: 1.4rem;
    }

    .qaTxt h5 {
        font-size: 1rem;
    }
}

.programDiv, .feeDiv {
    margin: 0 16rem;
}

@media (max-width: 768px) {
    .programDiv, .feeDiv {
        margin: 0 1rem;
    }
}

.feeDiv, .programDiv, .serviceDiv {
    /* padding: 0 200px; */
    /*padding: 0 100px;*/
}

.table td, .table th {
    vertical-align: middle;
}

.qaTxt {
    /* padding: 0 200px 100px 200px; */
    /*padding: 0 150px 100px 150px;*/
}

/*模擬對角線*/
.out {
    border-top: 40px #D6D3D6 solid; /*上邊框寬度等於表格第一行行高*/
    width: 0px; /*讓容器寬度為0*/
    height: 0px; /*讓容器高度為0*/
    border-left: 130px #BDBABD solid; /*左邊框寬度等於表格第一行第一格寬度*/
    position: relative; /*讓裡面的兩個子容器絕對定位*/
}

b {
    font-style: normal;
    display: block;
    position: absolute;
    top: -40px;
    left: -40px;
    width: 35px;
}

em {
    font-style: normal;
    display: block;
    position: absolute;
    top: -25px;
    left: -70px;
    width: 55x;
}

#accordion .qTitle {
    color: #a56f27;
}

#atmTable {
    width: 100%;
    margin: 1rem 0 0;
}

    #atmTable td, #atmTable th {
        border: 1px solid black;
        padding: .3rem;
    }

    #atmTable th {
        text-align: center;
        background-color: #f2f2f2;
        width: 12%;
        padding: .7rem 0;
        font-size: 1.2rem;
    }

#atmUl {
    list-style: decimal;
    margin: 0;
    padding: 0 0 0 1.2rem;
}

    #atmUl li {
        margin: 0;
        padding: 0;
        text-align: justify;
        text-justify: auto;
        font-size: 90%;
    }

.borderNone {
    border: none !important;
}

.uploadPicContain {
    position: relative;
    z-index: 10;
    margin: 16px 0;
    display: inline-block;
    height: 0;
}

    .uploadPicContain img {
        max-height: 200px;
        height: auto;
        width: auto;
    }

    .uploadPicContain span.signX {
        position: absolute;
        z-index: 20;
        border-radius: 99px;
        text-align: center;
        display: none;
        top: -9px;
        right: -9px;
        font-size: 12px;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

#mup {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px
}

    #mup input[type="button"] {
        padding: 0 5px;
        line-height: 0.8;
        display: inline-block;
        width: auto;
    }

.orderDemoPic img {
    max-width: 100%;
    height: 200px;
    width: auto;
    margin-bottom: 18px;
    display: block !important;
}

.orderDemoPic div {
    height: 200px;
    display: block !important;
}

/******************** pagination ********************/

.m-pagination {
    display: flex;
    width: 100%;
    justify-content: center
}

    .m-pagination .btn-prev, .btn-next {
        border: none;
    }

.m-pager {
    list-style: none;
    padding: 0;
    margin-bottom: 0px;
}

    .m-pager li {
        display: inline-block;
        width: 30px;
        height: 28px;
        margin: 0 5px;
        padding: 0 4px;
        line-height: 28px;
        font-size: 13px;
        box-sizing: border-box;
        vertical-align: top;
        text-align: center;
        background-color: #f4f4f5;
        color: #606266;
        border-radius: 2px;
        cursor: pointer;
    }

        .m-pager li:hover {
            color: #3c8cff;
        }

    .m-pager .active {
        background-color: #3c8cff;
        color: #fff;
        cursor: default;
    }

    .m-pager li:not(.active):hover {
        color: #3c8cff;
    }


    .m-pager .more::before {
        content: '...';
    }

    .m-pager .more.left:hover::before {
        content: '<<';
    }

    .m-pager .more.right:hover::before {
        content: '>>';
    }
